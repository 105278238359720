import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ValuesOne from "../components/values/Values_1";
import BlockPrincipal from "../components/block/Block_6";
import BlockSecondary from "../components/block/Block_1";

function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="About">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="About"
          Subheader="About"
          bgimg={`${rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[20] : rpdata?.stock?.[20]}`}
        />
        <BlockPrincipal
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image1={rpdata?.gallery?.length > 0 ? rpdata?.gallery?.[10] : rpdata?.stock?.[10]}
          image2={rpdata?.gallery?.length > 0 ? rpdata?.gallery?.[6] : rpdata?.stock?.[6]}
          image3={rpdata?.gallery?.length > 0 ? rpdata?.gallery?.[7] : rpdata?.stock?.[7]}
          image4={rpdata?.gallery?.length > 0 ? rpdata?.gallery?.[8] : rpdata?.stock?.[8]}
          listsAbout
        />
        <ValuesOne
          img1={rpdata?.gallery?.length > 0 ? rpdata?.gallery?.[8] : rpdata?.stock?.[8]}
          img2={rpdata?.gallery?.length > 0 ? rpdata?.gallery?.[9] : rpdata?.stock?.[9]}
          img3={rpdata?.gallery?.length > 0 ? rpdata?.gallery?.[11] : rpdata?.stock?.[10]}
        />

        <BlockSecondary
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image={rpdata?.gallery?.length > 0 ? rpdata?.gallery?.[12] : rpdata?.stock?.[12]}
        />
      </div>
    </BaseLayout>
  );
}

export default About;
